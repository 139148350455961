import { NgModule } from '@angular/core';

import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
//
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { StorageService } from './services/storage/storage.service';
import { AuthInterceptor } from './services/interceptors/auth.interceptor';
import { CartService } from './services/cart/cart.service';
import { UserService } from './services/user/user.service';
//  import { NgImageSliderModule } from 'ng-image-slider';
// import { AngularEmailAutocompleteComponent } from '../../node_modules/angular-mail-autocomplete/src/lib/angular-email-autocomplete.component';

import { NgxMaskModule } from 'ngx-mask';
import { LoadingScreenModule } from './e-commerce/components/loading-screen/loading-screen.module';


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    LoadingScreenModule,

    // NgImageSliderModule
    NgxMaskModule.forRoot()
  ],
  providers: [
    StorageService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    UserService,    
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
