import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingScreenComponent } from './loading-screen.component';

// import { UsuariosResponsablesAdminComponent } from './modules/usuarios-responsables-admin/usuarios-responsables-admin.component';


@NgModule({
  declarations: [
    LoadingScreenComponent
    // UsuariosResponsablesAdminComponent
  ],
  imports: [
    CommonModule,
  
  ],
  exports: [
    LoadingScreenComponent

  ]
})
export class LoadingScreenModule { }
