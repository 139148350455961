import { Component, OnInit,AfterViewInit } from '@angular/core';
import { LoadingScreenService } from '../../../services/loading-screen/loading-screen.service'

@Component({
  selector: 'app-loading-screen',
  templateUrl: './loading-screen.component.html',
  styleUrls: ['./loading-screen.component.scss']
})
export class LoadingScreenComponent implements OnInit {

  isLoading$ : boolean = true;

  constructor(
    private loadingScreenService : LoadingScreenService
  ) { }

  ngOnInit(): void {
    this.loadingScreenService.isLoading$.subscribe(res=>{
      this.isLoading$ = res
    })
  }

  ngAfterViewInit(){
    let timer = setTimeout(()=>{
      this.loadingScreenService.hide()
      clearTimeout(timer)
    },25000)
  }
}
