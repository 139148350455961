import { Injectable } from '@angular/core';
import * as localForage from 'localforage';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  public storage: any;

  constructor() {
    this.storage = localForage.createInstance({ name: 'ms' });
  }

  public getItem(key: string, defaultValue: any = null): Promise<any> {
    return new Promise((resolve) => {
      this.storage.getItem(key).then((value) => {
        resolve({ data: (value !== null) ? JSON.parse(value as string) : defaultValue });
      });
    });
  }

  public setItem(key: string, value: any): void {
    this.storage.setItem(key, JSON.stringify(value));
  }

  public getBidones(){
    return this.storage.getItem('IncluyeBidones');
  }

}
